import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { LogoContent } from "../../components/Logos"

// Logos are aligned because designers have exported the images in the same row with the same fixed height (that is, with padding hardcoded in)
const query = graphql`
  query {
    cirkul: file(
      relativePath: { eq: "images/referral_programs/customer_logos/cirkul.png" }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    heatwave: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/heatwave.png"
      }
    ) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    leaf: file(
      relativePath: { eq: "images/referral_programs/customer_logos/leaf.png" }
    ) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    lofree: file(
      relativePath: { eq: "images/referral_programs/customer_logos/lofree.png" }
    ) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    misfitsmarket: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/misfitsmarket.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    okabashi: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/okabashi.png"
      }
    ) {
      childImageSharp {
        fixed(height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    popsockets: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/popsockets.png"
      }
    ) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    rothys: file(
      relativePath: { eq: "images/referral_programs/customer_logos/rothys.png" }
    ) {
      childImageSharp {
        fixed(height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sennheiser: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/sennheiser.png"
      }
    ) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    smol: file(
      relativePath: { eq: "images/referral_programs/customer_logos/smol.png" }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    threadbeast: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/threadbeast.png"
      }
    ) {
      childImageSharp {
        fixed(height: 27) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    wishbeer: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/wishbeer.png"
      }
    ) {
      childImageSharp {
        fixed(height: 13) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    iubenda: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/iubenda.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    tempdrop: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/tempdrop.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    aceable: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/aceable.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    criticalpass: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/criticalpass.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    flattummy: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/flattummy.png"
      }
    ) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    trubrain: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/trubrain.png"
      }
    ) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    yfood: file(
      relativePath: { eq: "images/referral_programs/customer_logos/yfood.png" }
    ) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    eczemahoney: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/eczemahoney.png"
      }
    ) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    breazy: file(
      relativePath: { eq: "images/referral_programs/customer_logos/breazy.png" }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    vapordna: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/vapordna.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    magicalbutter: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/magicalbutter.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ecigaretteempire: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/ecigaretteempire.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    adorebeauty: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/adorebeauty.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    truegloryhair: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/truegloryhair.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    thrivecausematics: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/thrivecausematics.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bondisands: file(
      relativePath: {
        eq: "images/referral_programs/customer_logos/bondisands.png"
      }
    ) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

// Slugs defined in `content/industries/industries.json`
// Passed from Page Context set in gatsby-node
// I would have loved to have them live in industries.json but needed a way to
// pass in logo file path and height to graphql query. Workaround was to hardcode it here.
const logos = (slug, data) => {
  switch (slug) {
    case "apparel":
      return [
        data.rothys.childImageSharp.fixed,
        data.threadbeast.childImageSharp.fixed,
        data.okabashi.childImageSharp.fixed,
        data.heatwave.childImageSharp.fixed,
      ]
    case "gadget-accessories":
      return [
        data.sennheiser.childImageSharp.fixed,
        data.lofree.childImageSharp.fixed,
        data.leaf.childImageSharp.fixed,
        data.popsockets.childImageSharp.fixed,
      ]
    case "software-digital":
      return [
        data.iubenda.childImageSharp.fixed,
        data.tempdrop.childImageSharp.fixed,
        data.aceable.childImageSharp.fixed,
        data.criticalpass.childImageSharp.fixed,
      ]
    case "health-wellness":
      return [
        data.flattummy.childImageSharp.fixed,
        data.trubrain.childImageSharp.fixed,
        data.yfood.childImageSharp.fixed,
        data.eczemahoney.childImageSharp.fixed,
      ]
    case "vaporizers-ecigs":
      return [
        data.breazy.childImageSharp.fixed,
        data.vapordna.childImageSharp.fixed,
        data.magicalbutter.childImageSharp.fixed,
        data.ecigaretteempire.childImageSharp.fixed,
      ]
    case "beauty-fashion":
      return [
        data.adorebeauty.childImageSharp.fixed,
        data.truegloryhair.childImageSharp.fixed,
        data.thrivecausematics.childImageSharp.fixed,
        data.bondisands.childImageSharp.fixed,
      ]
    default:
      throw Error("Impossible outcome, check value of " + slug)
  }
}

export default ({ slug }) => {
  const data = useStaticQuery(query)
  return <LogoContent logos={logos(slug, data)} />
}
