import React from "react"

import Grid from "../../components/Grid"
import Button from "../../components/Button"
import css from "./RunsItself.module.scss"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const query = graphql`
  query {
    automation: file(
      relativePath: { eq: "images/referral_programs/automation_options.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const RunsItself = () => {
  const data = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <h2>A referral program that runs itself</h2>
        <p>
          Customers are invited automatically. Rewards are automatically paid
          out. ReferralCandy frees up your time so you can run your business.
        </p>
        <Button purpose="primary" href="https://my.referralcandy.com/signup">
          Get Started Now
        </Button>
      </div>
      <div className={css.image}>
        <Img
          fluid={data.automation.childImageSharp.fluid}
          alt="ReferralCandy Automation Options"
        />
      </div>
    </Grid>
  )
}

export default RunsItself
