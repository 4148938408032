import React from "react"
import Img from "gatsby-image"
import Grid from "../Grid"
import css from "./index.module.scss"

const DEFAULT_TITLE = (
  <>
    More than <span className={css.underline}>30,000</span> online stores across
    industries have trusted ReferralCandy
  </>
)

export const LogoContent = ({ title = DEFAULT_TITLE, logos }) => (
  <>
    <h3 className={css.title}>{DEFAULT_TITLE}</h3>
    <ul className={css.store_logos}>
      {logos.map((logo, index) => (
        <li key={index}>
          <Img fixed={logo} alt="Referral Candy Customer Logo" />
        </li>
      ))}
    </ul>
  </>
)

// title!: string
// logos: Array<image.childImageSharp.fixed>
const Logos = (props) => {
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <LogoContent {...props} />
    </Grid>
  )
}

export default Logos
