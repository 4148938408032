import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import css from "./index.module.scss"
import Grid from "../../../Grid"

import { FaArrowRight } from "react-icons/fa"

// import bodyType from "../../../../helpers/fontType.module.scss"

const query = graphql`
  query {
    picture_all_industries: file(
      relativePath: { eq: "images/referral_programs/all_industries.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allIndustriesJson(sort: { fields: [priority], order: DESC }) {
      edges {
        node {
          title
          slug
          link_image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const ReferralProgramCard = (props) => {
  const { slug, title, linkImage } = props
  return (
    <Link className={css.card} to={`/referral-programs/${slug}`}>
      <div className={css.cardimage}>
        <Img
          fluid={linkImage.childImageSharp.fluid}
          alt={`Referral Candy - referral program for ${title} store`}
        />
      </div>
      <div className={css.cardtext}>
        {title}&nbsp; <FaArrowRight className={css.item_icon} />
      </div>
    </Link>
  )
}

const CardList = ({ excludeSlug = "", linkToFullList = false }) => {
  const data = useStaticQuery(query)
  return (
    <ul className={css.items}>
      {data.allIndustriesJson.edges
        .filter((edge) => edge.node.slug !== excludeSlug)
        .map((edge) => {
          const { title, slug, link_image } = edge.node
          return (
            <li key={title}>
              <ReferralProgramCard
                title={title}
                slug={slug}
                linkImage={link_image}
              />
            </li>
          )
        })}
      {linkToFullList && (
        <li>
          <ReferralProgramCard
            title="All industries"
            slug=""
            linkImage={data.picture_all_industries}
          />
        </li>
      )}
    </ul>
  )
}

export default CardList

export const CardListSection = () => {
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <h1 className={css.title}>
          ReferralCandy works for all types of businesses
        </h1>
      </div>
      <CardList />
    </Grid>
  )
}
