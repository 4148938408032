import React from "react"

import Grid from "../../components/Grid"
import Button from "../../components/Button"
import css from "./DataDriven.module.scss"

import { DataDrivenSlideshow } from "../../components/Sections/FeaturesDataDriven"

const DataDriven = () => {
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.slideshow}>
        <DataDrivenSlideshow />
      </div>
      <div className={css.text}>
        <h2 className={css.no_padding_top}>
          Grow your sales with data-driven insights
        </h2>
        <p>
          See data-driven insights to track new traffic sources and see which of
          your customers are superstar referrers
        </p>
        <Button purpose="primary" href="https://my.referralcandy.com/signup">
          Get Referral Sales
        </Button>
      </div>
    </Grid>
  )
}

export default DataDriven
