import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Grid from "../../components/Grid"
import Layout from "../../components/Layout"
import Testimonial from "../../components/Testimonial"
import RunsItself from "./RunsItself"
import DataDriven from "./DataDriven"
import { MarketingAppContent } from "../../components/Sections/FeaturesMarketingApp"
import CardList from "../../components/Sections/ReferralPrograms/CardList"
import ReferralProgramLogos from "./ReferralProgramLogos"

import css from "./index.module.scss"

function ReferralProgramTemplate(props) {
  const {
    data: { industriesJson },
    pageContext,
  } = props
  const {
    title,
    slug,
    metadata_title,
    hero_image,
    testimonial,
    testimonial_cite,
    testimonial_position,
    testimonial_showcase,
    testimonial_avatar,
    testimonial_url,
  } = industriesJson
  const testimonialContent = {
    quote: testimonial,
    cite: testimonial_cite,
    position: testimonial_position,
    url: testimonial_url,
  }
  return (
    <Layout
      metadata={{ title: metadata_title }}
      pathname={props.location.pathname}
    >
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.title}>
          Sell more on
          <br />
          your {title.toLowerCase()} store
        </h1>
        <div className={css.imageWrapper}>
          <div className={css.image}>
            <Img
              fluid={hero_image.childImageSharp.fluid}
              alt={`Referral candy - online ${title} referral programs example`}
            />
          </div>
        </div>
        <div className={css.content}>
          <ReferralProgramLogos slug={pageContext.slug} />
        </div>
      </Grid>
      <RunsItself />
      <Testimonial
        showcase={testimonial_showcase}
        avatar={testimonial_avatar}
        content={testimonialContent}
      />
      <DataDriven />
      <Grid className={css.section}>
        <h2 className={css.title}>
          Complete your marketing stack
          <br />
          with a referral program
        </h2>
        <MarketingAppContent />
      </Grid>
      <Grid className={css.section} centeredClassName={css.centered}>
        <h2 className={css.title}>Running a different type of business?</h2>
        <CardList excludeSlug={slug} linkToFullList />
      </Grid>
    </Layout>
  )
}

export default ReferralProgramTemplate

export const pageQuery = graphql`
  query($slug: String) {
    industriesJson(slug: { eq: $slug }) {
      slug
      title
      testimonial
      testimonial_cite
      testimonial_position
      testimonial_url
      testimonial_showcase {
        childImageSharp {
          fixed(width: 305) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      testimonial_avatar {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      metadata_title
      hero_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
